/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxiosError } from 'axios';
import apiCaller from '../api_caller';
import get from 'lodash.get';

interface AuthenticationElements {
  error: HTMLElement;
  errorText: HTMLElement;
  form: HTMLFormElement;
  parent: HTMLElement;
  password: HTMLInputElement;
  submitBtn: HTMLButtonElement;
}

interface AuthenticationState {
  type: 'hub' | 'stream';
  streamId?: number | string;
}

interface AuthenticationRequest {
  passphrase: string;
  type: 'hub' | 'stream';
  streamId?: number | string;
}

class AuthenticationComponent {
  private readonly BUSY_CLASS_NAME: string = 'uf-is-processing';

  private readonly REDIRECT_CLASS_NAME: string = 'uf-is-redirecting';

  private readonly HIDDEN_CLASS_NAME: string = 'uf-hidden';

  private DEFAULT_ERROR_MESSAGE: string = 'Something went wrong';

  private readonly selectors: { [key: string]: string } = {
    errorById: 'uf-login-error',
    errorText: '#uf-login-error-text',
    form: '#uf-login-form',
    parentById: 'uf-authentication',
    password: '#uf-login-passphrase',
    submitBtn: '#uf-login-submit',
  };

  private dom!: AuthenticationElements;

  private state!: AuthenticationState;

  public constructor(config: AuthenticationState) {
    this.state = config;

    if (this.setBindings()) {
      this.init();
    }
  }

  private setBindings = (): boolean => {
    const parent = document.getElementById(this.selectors.parentById) as HTMLElement;
    const error = document.getElementById(this.selectors.errorById) as HTMLElement;
    if (!parent || !error) return false;

    const form = parent.querySelector(this.selectors.form) as HTMLFormElement;
    const errorText = error.querySelector(this.selectors.errorText) as HTMLElement;
    if (!form || !errorText) return false;

    const password = form.querySelector(this.selectors.password) as HTMLInputElement;
    const submitBtn = form.querySelector(this.selectors.submitBtn) as HTMLButtonElement;

    if (!password || !submitBtn) {
      return false;
    }

    if (errorText.dataset.defaultText) {
      this.DEFAULT_ERROR_MESSAGE = errorText.dataset.defaultText;
    }

    this.dom = { error, errorText, form, parent, password, submitBtn };
    return true;
  };

  public init(): void {
    this.dom.form!.addEventListener('submit', this.submit);
  }

  private submit = (event: Event): void => {
    event.preventDefault();

    if (this.isBusy() || this.isRedirect()) {
      return;
    }

    const passphrase = this.dom.password.value;
    if (passphrase.length < 6 || passphrase.length > 40) {
      this.showAlert(this.DEFAULT_ERROR_MESSAGE);
      return;
    }

    this.setBusy();

    const data: AuthenticationRequest = {
      passphrase,
      type: this.state.type,
    };

    if (this.state.streamId) {
      data.streamId = this.state.streamId;
    }

    apiCaller.post('/themes/authenticate', data).then(this.handleSuccess).catch(this.handleError);
  };

  private isBusy = (): boolean => this.dom.parent.classList.contains(this.BUSY_CLASS_NAME);

  private isRedirect = (): boolean => this.dom.parent.classList.contains(this.REDIRECT_CLASS_NAME);

  private setBusy = (): void => {
    this.resetAlert();
    this.dom.parent.classList.add(this.BUSY_CLASS_NAME);
    this.dom.password.setAttribute('disabled', 'disabled');
    this.dom.submitBtn.setAttribute('disabled', 'disabled');
  };

  private clearBusy = (): void => {
    this.dom.parent.classList.remove(this.BUSY_CLASS_NAME);
    this.dom.password.removeAttribute('disabled');
    this.dom.submitBtn.removeAttribute('disabled');
  };

  private setRedirect = (): void => this.dom.parent.classList.add(this.REDIRECT_CLASS_NAME);

  private handleSuccess = (): void => {
    this.clearBusy();
    this.setRedirect();
    // TODO: UFA track successful login (type: hub | stream)
    window.location.reload();
  };

  private handleError = (axiosError: AxiosError): void => {
    this.clearBusy();
    this.showAlert(this.getErrorMessage(axiosError));
  };

  private getErrorMessage = (axiosError: AxiosError): string => {
    const error = get(axiosError, 'response.data.meta.error');
    return get(error, 'message[0]') || get(error, 'generic[0]', this.DEFAULT_ERROR_MESSAGE);
  };

  private showAlert = (errorMessage: string): void => {
    this.dom.errorText.innerText = errorMessage;
    this.dom.error.classList.remove(this.HIDDEN_CLASS_NAME);
  };

  private resetAlert = (): void => {
    this.dom.errorText.innerText = this.DEFAULT_ERROR_MESSAGE;
    this.dom.error.classList.add(this.HIDDEN_CLASS_NAME);
  };
}

export default AuthenticationComponent;
