/* eslint-disable no-new */
import './hub_login_page.scss';

import '../../../common/privacy/privacy';
import {
  hubEventLoadDispatch,
  sharedHubEventsInit,
} from '../../../common/hub_events/shared_hub_events';
import AuthenticationComponent from '../../../common/authentication/authentication';

const main = () => {
  sharedHubEventsInit();
  new AuthenticationComponent({ type: 'hub' });
  hubEventLoadDispatch();
};

document.addEventListener('DOMContentLoaded', main);
